.nav-bar {
  background-color: #9D8FCC;
  font-size: 2.53vh;
  font-family: 'Mina', sans-serif;
  color: white;
  text-shadow: 0px 0.52vh 0.422vh rgba(0, 0, 0, 0.25) !important;
  filter: drop-shadow(0px 0.52vh 0.422vh rgba(0, 0, 0, 0.25));
  z-index: 1;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 3.8vh;
  display: flex;
  width: 100%;
  text-align: center;
  align-items: center;
}

.cobble {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 34vh;
  display: flex;
  background-size: 80vh 80vh;
}

.nav-bar-mobile {
  background-color: #9D8FCC;
  font-size: 2.53vh;
  font-family: 'Mina', sans-serif;
  color: white;
  z-index: 1;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 60%;
  text-align: center;
  align-items: center;
}

.nav-bar-mobile-wrapper {
  justify-content: center;
  display: flex;
  background-color: #9D8FCC;
  font-size: 2.53vh;
  font-family: 'Mina', sans-serif;
  color: white;
  text-shadow: 0px 0.52vh 0.422vh rgba(0, 0, 0, 0.25) !important;
  filter: drop-shadow(0px 0.52vh 0.422vh rgba(0, 0, 0, 0.25));
  z-index: 1;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 4.5vh;
  padding: 0;
  width: 100%;
}

.nav-bar-item {
  flex: 1;
  width: 100%;
  background-color: #9D8FCC;
  border-radius: 10px;
  transition: 0.4s;
  color: #fffeff;
}

.nav-bar-item-mobile {
  flex: 1;
  width: 100%;
  background-color: #9D8FCC;
  border-radius: 10px;
  transition: 0.4s;
  color: #fffeff;
  padding-top: 1vh;
}

.nav-bar-item:hover {
  cursor: pointer;
  filter: drop-shadow(0px 0.52vh 0.422vh rgba(0, 0, 0, 0.25)) brightness(1.2);
}

.main-content {
  background-color: #9BA353;
  font-size: 36px;
  font-family: 'Mina', sans-serif;
  color: #fffeff;
  text-shadow: 0px 0.317vh 0.422vh rgba(0, 0, 0, 0.25);
  flex-grow: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.title-text {
    margin: 0;
    padding: 0;
    font-size: 6.5vh;
    font-family: 'IM Fell English SC' , serif;
    position: absolute;
    left: 41vh;
    top: 10vh;
    line-height: 6vh;
}

.title-text-mobile {
    margin-right: 0.2em;
    margin-left: 0.1em;
    padding: 0;
    font-size: 170%;
    white-space: nowrap;
    font-family: 'IM Fell English SC' , serif;
    line-height: 1em;
    justify-self: center;
    align-self: center;
    flex: 1;
}

.info-text {
    margin: 0;
    margin-top: 1.5vh;
    padding: 0;
    color: #fffeff;
    font-size: 3vh;
    font-family: 'Mina', sans-serif;
    position: absolute;
    line-height: 4vh;
    right: 6vh;
    top: 4vh;
    text-shadow: 0px 0.52vh 0.422vh rgba(0, 0, 0, 0.25) !important;
}

@media (max-aspect-ratio: 6/5) {
  .info-text {
    display: none;
  }
}

@media (max-aspect-ratio: 17/20) {
  .title-text {
    display: none;
  }
  .nav-bar-item {
    display: none;
  }
  .nav-bar {
    display: none;
  }
  .teapot-svg {
    display: none;
  }
  .cobble {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 9em;
    background-size: 60vh 60vh;
  }
  .home-container {
    font-size: 1.2em;
  }
  .hamburger-menu {
    user-select: none;
    padding-top: 3vh;
    z-index: 3;
    width: 100%;
    transition: 0.4s;
    height: 45vh;
    position: absolute;
    right: 0;
    top: 0;
    background-color: #9D8FCC;
    filter: drop-shadow(0px 5px 4px rgba(0, 0, 0, 0.25));
    will-change: filter;
    border-radius: 0.5vh;
    display: flex;
    flex-direction: column;
  }
  
  .hamburger-menu-item {
    user-select: none;
    margin-left: 3vh;
    text-shadow: 0px 0.52vh 0.422vh rgba(0, 0, 0, 0.25) !important;
    font-family: 'Mina', sans-serif;
    flex: 1;
    background-color: #9D8FCC;
    font-size: 4.5vh;
    transition: 0.4s;
    color: #fffeff;
  }
}

@media (min-aspect-ratio: 17/20) {
  .hamburger {
    display: none;
  }
  .hamburger-line1 {
    display: none;
  }
  .hamburger-line2 {
    display: none;
  }
  .hamburger-line3 {
    display: none;
  }
  .home-container {
    font-size: 4vh;
    justify-content: center;
  }
  .teapot-svg-mobile {
    display: none;
  }
  .title-text-mobile {
    display: none;
  }
  .nav-bar-mobile {
    display: none;
  }
  .nav-bar-item-mobile {
    display: none;
  }
  .nav-bar-mobile-wrapper {
    display: none;
  }
  .hamburger-menu-item {
    display: none;
  }
  .hamburger-menu {
    display: none;
  }
}

.hamburger {
  z-index: 4;
  width: 8vh;
  height: 8vh;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #9D8FCC;
  filter: drop-shadow(0px 5px 4px rgba(0, 0, 0, 0.25));
  border-radius: 0.5vh;
}

.hamburger-line1 {
  user-select: none;
  width: 4vh;
  height: 0.5vh;
  background-color: #fffeff;
  position: absolute;
  top: 2vh;
  right: 2vh;
  transition: 0.4s;
  z-index: 5;
  box-shadow: 0px 0.4vh 0.4vh rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}

.hamburger-line2 {
  user-select: none;
  width: 4vh;
  height: 0.5vh;
  background-color: #fffeff;
  position: absolute;
  top: 3.5vh;
  right: 2vh;
  transition: 0.4s;
  z-index: 5;
  box-shadow: 0px 0.4vh 0.4vh rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}

.hamburger-line3 {
  user-select: none;
  width: 4vh;
  height: 0.5vh;
  background-color: #fffeff;
  position: absolute;
  top: 5vh;
  right: 2vh;
  transition: 0.4s;
  z-index: 5;
  box-shadow: 0px 0.4vh 0.4vh rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}

.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button {
    filter: drop-shadow(0px 0.52vh 0.422vh rgba(0, 0, 0, 0.25));
    transition: 0.3s;
}

.teapot-svg {
  position: absolute;
  width: 32.65vh;
  height: 32vh;
  top: 0.6vh;
  left: 7vh;
  z-index: 2;
}

.teapot-svg-mobile {

  z-index: 2;
}

@media(hover: hover) and (pointer: fine) {
  .button:hover {
    filter: drop-shadow(0px 0.52vh 0.422vh rgba(0, 0, 0, 0.25)) brightness(1.2);
  }
}

.button {
  transition: all .2s ease-in-out;
  user-select: none;
}

.button:active {
  transform: scale(0.9);
	transition: .1s;
  box-shadow: none;
}

input {
  transition: 0.1s;
}

input[type=checkbox] {
  accent-color: #FFB30F;
  border-radius: 3vh;
}

.discord-button {
  font-size: 3.1vh;
  background-color: #5865F2 !important;
  color: white !important;
  animation: discord-glow 2s ease-in-out infinite;
}

@keyframes discord-glow {
  0% {
    box-shadow: 0 0 5px #5865F2,
                0 0 10px #5865F2,
                0 0 15px #5865F2;
  }
  50% {
    box-shadow: 0 0 10px #5865F2,
                0 0 20px #5865F2,
                0 0 30px #5865F2;
  }
  100% {
    box-shadow: 0 0 5px #5865F2,
                0 0 10px #5865F2,
                0 0 15px #5865F2;
  }
}

@media (max-aspect-ratio: 17/20) {
  .discord-button {
    font-size: 2.5vh !important;
  }
}

